export default [
    {
        // [0]
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
    },
    {
        // [1]
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
    },
    {
        // [2]
        type: "select",
        margin: true,
        showLabel: true,
        label: "Client Status",
        model: null,
        options: [
            { label: "All", id: "0" },
            { label: "Active", id: "1" },
            { label: "Canceled", id: "4" },
            { label: "Closed", id: "6" },
            { label: "Hold", id: "2" },
            { label: "Loyal", id: "5" },
            { label: "Transition", id: "3" },
        ],
        reduce: "id",
        seletText: "label",
        cols: 12,
    },

    {
        // [3]
        type: "select",
        margin: true,
        showLabel: true,
        label: "Program",
        model: null,
        options: [
            { id: "0", label: "ALL" },
            { id: "2", label: "Boost Credit" },
            { id: "3", label: "Credit Experts" },
            { id: "4", label: "Debt Solution" },
            { id: "7", label: "Specialist" },
        ],
        reduce: "id",
        seletText: "label",
        cols: 7,
    },
    {
        // [4]
        type: "select",
        margin: true,
        showLabel: true,
        label: "Advisor",
        model: null,
        options: [
            { label: "ALL", id: "0" },
        ],
        reduce: "id",
        seletText: "label",
        cols: 5,
    },
];
