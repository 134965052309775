const fieldsSent = [
    {
        key: "name_client",
        label: "Client Name",
    },
    {
        key: "account",

    },
    {
        key: "program_name",
        label: "Program",
    },
    {
        key: "name_specialist",
        label: "Request By",
    },
    {
        key: "date_send",
        label: "Sent",

    },
    {
        key: "files",
        thClass: "text-center",


    },

    {
        key: "tracking",
        thClass: "text-center",
        tdClass: "text-center",
    },

];

export default {
    fieldsSent,
};
